import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledFaq = styled.div`
  padding-top: ${Rem(12)};
  padding-bottom: ${Rem(82)};
  padding-right: 0;
  width:100%;
  align-items: center;
  color: ${Colors.black};

  .faq-row-wrapper {
    padding: 0;
    @media (${BP.ipad}) {
      padding: 0;
    }
    .faq-body {
      background-color: ${(props) => props.color || Colors.yellow40};
      border-radius: ${Rem(0)};
      @media (${BP.ipad}) {
        border-radius: ${Rem(12)};
      }
      .faq-row {
        max-width: ${Rem(1248)};
        margin: 0 auto;
        border-color:  ${Colors.white};
        padding: 5px 0;
        padding-left: ${Rem(10)};
        @media (${BP.ipad}) {
          padding-left: ${Rem(12)};
        }
        .row-title {
          font-size:${Rem(18)};
          padding: 10px 0;
        }
        .row-content {
          .row-content-text {
            font-weight: normal;
            color: ${Colors.grayDark};
            padding-right: ${Rem(12)};
          }
        }
      }
    }
  }
  ${(props) => 
    props.isCourses
    && css`
    .faq-row-wrapper {
      padding: 0 1rem;
      @media (${BP.ipad}) {
        padding: 0;
      }
      .faq-body {
        section:first-child {
          .row-title {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            }
        }
        section:last-child {
          .row-content {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
        @media (${BP.ipad}) {
          border-radius: 15px;
        }
        .faq-row {
          padding: 0;
          padding-left: 0;
          @media (${BP.ipad}) {
            padding-left: 0;
          }
          .row-title {
            padding: 1.5rem;
            &.expanding, &.expanded {
              background: ${Colors.yellow};
            }
          }
          .row-content {
            &[aria-expanded="true"]{
              background: ${Colors.yellow};
            }
            .row-content-text {
              padding: 0 1.5rem 1rem 1.5rem;
            }
          }
        }
      }
    }
  `}
  ${(props) =>
    props.isValentine
    && css`
    .faq-row-wrapper {
      padding: 0 1rem;
      @media (${BP.ipad}) {
        padding: 0;
      }
      .faq-body {
        .faq-row {
          padding: 0;
          .row-title {
            padding: 1rem;
            border: 1px solid ${Colors.yellow80};
          }
          .row-content {
            border-color: ${Colors.yellow80};
            border-style: solid;
            &[aria-expanded="true"]{
              background: ${Colors.yellowClear};
              padding: 1rem;
              border-width: 0px 1px;
            }
          }
        }
        section:first-child {
          .row-title {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }
        section:last-child {
          .row-content, .row-title {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          .row-content {
            &[aria-expanded="true"]{
              border-bottom-width: 1px !important;
            }
          }
        }
      }
    }
  `}
`;

export const StyledFaqSubtitle =  styled.p`
  font-size: 18px;
  max-width: 78rem;
  margin: 0 auto;
  padding-left: 10px;
  padding-bottom: 10px;
`;
